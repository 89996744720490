import { deviceWidth } from './deviceWidth';
import { renderStoreName } from './renderStoreName';

/**
 * function used to conditionally check whether
 * banner image should be dimmed or not.
 * if it is not custom store return false for brightness(0.7)
 * otherwise check if either store name or store logo is being rendered.
 * if it is return false, otherwise return inverse of the first array value
 * @param {*} theme
 * @param {*} config
 * @return {*} array
 */
export const returnImageBrightnessBoolean = (theme, config) => {
  const object = renderStoreName(deviceWidth, config);
  const uniqueValues = [...new Set(Object.values(object))];
  // if unique values length > 0, either store name or store logo is rendered so return false.
  if (!theme?.custom_theme_flag || uniqueValues.length > 1) {
    return false;
  } else {
    // return inverse of first array value
    return !uniqueValues[0];
  }
};
