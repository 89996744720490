import styled from 'styled-components';

export const HeroBannerWrapperComponent = styled.div`
  position: flex;
  margin-bottom: 72px;
  @media screen and (max-width: 766px) {
    margin-bottom: 64px;
    &.herobanner6 {
      margin-bottom: 0;
    }
  }

  ${(props) => props.additionalStyles}

  .arrow-btns {
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
`;
