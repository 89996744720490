import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import {
  HeroBannerComponent,
  HeroBannerHeading,
  HeroBannerImage,
  HeroBannerInteractiveBody,
  HeroBannerInteractivePosition,
  HeroBannerMainContent,
  HeroBannerSection,
  HeroBannerStoreImage,
} from './HeroBanner7.styles';
import { useSelector, useDispatch } from 'react-redux';
import { getHeroBannerHeightCalculation } from 'src/redux/actions';
import { Slider } from '@/components/ReactSlickCarousel';
import { bannerImageClick } from '@/utils/bannerImageClick';
import { returnImageBrightnessBoolean } from '@/utils/returnImageBrightnessBoolean';
import { renderStoreName } from '@/utils/renderStoreName';
import useBannerImages from '../hooks/useBannerImages';
import BannerImageSliderNextPrevButtons from '../common/BannerImageSliderNextPrevButtons';
import { sliderSettings } from '../utils';
import { useSSRSelector } from '@/redux/ssrStore';
import { deviceWidth } from '@/utils/deviceWidth';

const HeroBanner7 = (props) => {
  const sliderRef = useRef();
  const dispatch = useDispatch();

  const { heroBannerHeight } = useSelector(
    (state) => state.commonReducer.heightCalculation
  );

  const ssrStoreData = useSSRSelector((state) => state.storeReducer.store);

  const { store_info: storeInfo, theme } = ssrStoreData || {};
  const [bannerImages] = useBannerImages();

  const [state, setState] = useState({
    isHeightFetched: false,
    heightFetched: 0,
  });

  useEffect(() => {
    dispatch(getHeroBannerHeightCalculation());
  }, []);

  useEffect(() => {
    if (!state.heightFetched) {
      setState((state) => ({
        ...state,
        heightFetched: heroBannerHeight,
        isHeightFetched: true,
      }));
    }
  }, [heroBannerHeight]);

  return storeInfo ? (
    // <HeroBannerWrapperComponent7
    //   bannerFixedRatio={props.banner_fixed_ratio}
    //   heightCalculation={state.heightFetched}
    // >
    <div className={`heroBannerWrapper ${!bannerImages?.length ? 'placeholder' : ''}`}>
      {bannerImages?.length > 1 && (
        <BannerImageSliderNextPrevButtons sliderRef={sliderRef} />
      )}
      <Slider ref={(slider) => (sliderRef.current = slider)} {...sliderSettings}>
        {bannerImages?.map((img, idx) => {
          return (
            <HeroBannerComponent
              key={img.id}
              className={img?.image_href !== '' ? 'pointer' : ''}
              bannerFixedRatio={theme?.banner_fixed_ratio}
              heightCalculation={state.heightFetched}
              onClick={() => bannerImageClick(theme, img)}
            >
              <HeroBannerMainContent>
                <HeroBannerImage
                  height={idx === 0}
                  // if banner index === 0 check for image brightness, otherwise return true
                  imageBrightness={
                    idx === 0 ? returnImageBrightnessBoolean(theme, props?.config) : true
                  }
                >
                  {img?.image_url && (
                    <Image
                      layout="fill"
                      objectFit="cover"
                      objectPosition={deviceWidth < 767 ? 'center top' : 'center center'}
                      className="next-banner-image"
                      src={img?.image_url}
                      priority={idx == 0}
                    />
                  )}
                </HeroBannerImage>
                {idx === 0 && (
                  <HeroBannerInteractivePosition>
                    <HeroBannerInteractiveBody>
                      <HeroBannerSection>
                        {renderStoreName(deviceWidth, props?.config)?.logo &&
                          storeInfo?.logo_image && (
                            <HeroBannerStoreImage>
                              <Image
                                src={storeInfo?.logo_image}
                                className="next-store-image"
                                alt="store logo"
                                height="100%"
                                width="100%"
                                objectFit="cover"
                                objectPosition="center center"
                              />
                            </HeroBannerStoreImage>
                          )}
                        {renderStoreName(deviceWidth, props?.config)?.storeName && (
                          <HeroBannerHeading
                            primaryColor={theme?.colors?.primary_color || '#ffffff'}
                          >
                            {storeInfo?.name}
                          </HeroBannerHeading>
                        )}
                      </HeroBannerSection>
                    </HeroBannerInteractiveBody>
                  </HeroBannerInteractivePosition>
                )}
              </HeroBannerMainContent>
            </HeroBannerComponent>
          );
        })}
      </Slider>
      {/* </HeroBannerWrapperComponent7> */}
    </div>
  ) : (
    <></>
  );
};

export default HeroBanner7;
